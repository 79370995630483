import React from "react"

import style from "./footer.module.css"

export default function Footer() {
  return (
    <footer className={`footer ${style.footer}`}>
      <div className="container">
        <div className="row">
          <hr className="col" />
        </div>
        <div className="row">
          <div className="col">
            <div style={{ textAlign: "center" }}>
              © {new Date().getFullYear()} John VanSickle
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
