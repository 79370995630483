import { Link } from "gatsby"
import React from "react"
import style from "./header.module.css"
import {
  BriefcaseIcon,
  ArchiveIcon,
  FileIcon,
  PersonIcon,
} from "@primer/octicons-react"

type HeaderProps = {
  siteTitle: string
}

const Header = (props: HeaderProps) => (
  <header>
    <div className="container-lg p-0">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link to="/" className="navbar-brand">
          {props.siteTitle}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to="/technologies" className={`nav-link ${style.navLink}`}>
                <BriefcaseIcon />
                Recent Technologies
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/projects" className={`nav-link ${style.navLink}`}>
                <ArchiveIcon />
                Personal Projects
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/resume" className={`nav-link ${style.navLink}`}>
                <FileIcon />
                Resume
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/contact" className={`nav-link ${style.navLink}`}>
                <PersonIcon />
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
)

export default Header
